<template>
  <div class="content">
    <div class="bannerView">
      <img :src="bannerUrl" alt="" />
    </div>
    <div class="main">
      <section class="story">
        <div class="title">我們的故事</div>
        <div v-html="our_story" class="html-view"></div>
      </section>
      <section class="history">
        <div class="title">博銳歷史</div>
        <span
          >自1954年起博銳在彈性體材料的硬度測試領域上始終保持領先的地位</span
        >
        <div class="swiperView">
          <div class="swiperBox">
            <swiper :options="swiperOptionOne" ref="mySwiperOne">
              <swiper-slide v-for="(item, index) in history" :key="index">
                <div class="swiperItem">
                  <div class="imgView">
                    <img :src="item.attachment_url" alt="" />
                  </div>
                  <p>{{ item.description }}</p>
                  <div class="yearView">
                    <div class="line"></div>
                    <div class="spot"></div>
                    <div class="year">{{ item.year }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="history-swiper-button-prev swiper-button-prev swiperBtn"
              slot="button-prev"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <div
              class="history-swiper-button-next swiper-button-next swiperBtn"
              slot="button-next"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </section>
      <section class="people">
        <div class="swiperView">
          <div class="swiperBox">
            <swiper :options="swiperOptionTwo" ref="mySwiperOne">
              <swiper-slide v-for="(item, index) in people" :key="index">
                <div class="swiperItem">
                  <div class="leftView">
                    <div class="photoView">
                      <img :src="item.attachment_url" alt="" />
                    </div>
                    <div class="txtView">{{ item.description }}</div>
                  </div>
                  <div class="rightView">
                    <p>{{ item.name }}</p>
                    <p>{{ item.position }}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="people-swiper-button-prev swiper-button-prev swiperBtn"
              slot="button-prev"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <div
              class="people-swiper-button-next swiper-button-next swiperBtn"
              slot="button-next"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </section>
      <section class="videoWrap">
        <div class="videoBox" @click="videoStart">
          <img class="img" :src="about_video_pic" alt="" />
          <img class="btn" src="../assets/images/videoBtn.png" alt="" />
        </div>
      </section>
    </div>
    <div class="mainP">
      <div class="achievement">
        <div class="leftView">
          <img :src="about_pic" alt="" />
        </div>
        <div class="rightView">
          <h3>{{ about_words_title }}</h3>
          <p>{{ about_words }}</p>
          <router-link to="/use_cases" class="link"
            >更多博銳用戶 >>
          </router-link>
        </div>
      </div>
      <choice-view />
    </div>
    <video-dialog
      :show="videoDialogShow"
      :data="videoDialogData"
      @close="videoDialogShow = false"
    />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import choiceView from "@/components/module/choice";
import videoDialog from "@/components/video-dialog";
export default {
  name: "About",
  components: {
    swiper,
    swiperSlide,
    choiceView,
    videoDialog,
  },
  data() {
    return {
      swiperOptionOne: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 20,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".history-swiper-button-next",
          prevEl: ".history-swiper-button-prev",
        },
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      },
      swiperOptionTwo: {
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".people-swiper-button-next",
          prevEl: ".people-swiper-button-prev",
        },
      },
      bannerUrl: "",
      our_story: "",
      history: [],
      people: [],
      videoDialogShow: false,
      videoDialogData: {},

      about_words_title: "",
      about_words: "",
      about_pic: "",
      about_video_pic: "",
      about_video: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperOne.swiper;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.about().then((res) => {
        // this.bannerUrl = res.data.banner;
        // this.history = res.data.history;
        // this.people = res.data.people;
        // this.video = res.data.video;
        if (res.code == 200) {
          this.bannerUrl = res.data.about.banner_url;
          this.our_story = res.data.about.our_story;
          this.about_words_title = res.data.about.about_words_title;
          this.about_words = res.data.about.about_words;
          this.about_pic = res.data.about.about_pic;
          this.about_video_pic = res.data.about.about_video_pic;
          this.about_video = res.data.about.about_video;

          this.history = res.data.history;
          this.people = res.data.people;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    videoStart() {
      this.videoDialogData = {
        videoUrl: this.about_video,
      };
      this.videoDialogShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/about.scss";
</style>